import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import LogoFb from "../images/logos/brand/grey-fb.svg";
import LogoTwitter from "../images/logos/brand/grey-twitter.svg";
import LogoVimeo from "../images/logos/brand/grey-vimeo.svg";
import LogoAmazon from "../images/logos/brand/grey-amazon.svg";
import LogoYoutube from "../images/logos/brand/grey-youtube.svg";
import LogoItunes from "../images/logos/brand/grey-itunes.svg";
import IconCheck from "../images/icons/interface/check.svg";
import IconService1 from "../images/icons/theme/tools/IconService1.svg";
import IconService2 from "../images/icons/theme/tools/IconService2.svg";
import Divider1 from "../images/dividers/divider-3.svg";
import DecorationLine1 from "../images/decorations/deco-lines-1.svg";
import { GatsbyImage } from "gatsby-plugin-image";
// import useInViewPort from "../hooks/useInViewPort";
// import { Helmet } from "react-helmet";
//TOADD import { IndexJsonLd } from "../components/jsonld/IndexJsonLd";


const IndexPage = ({ data }) => {
  // const [isInViewRef, isInView] = useInViewPort();

  return (
    <Layout isMainPage footerBgClass="bg-primary-alt">
      <div className="index-page">
        {/* <IndexJsonLd /> */}
        <SectionFirstScreen data={data} />
        <SectionTrustedCompanies />
        <SectionTopUXAgency data={data} />
        <SectionDesignTeams />
        <SectionExpertise data={data} />
        <SectionIndustries />
        <SectionOurProcess data={data} />
        <SectionOurBook data={data} />
        {/* <div ref={isInViewRef}>
          
        </div> */}
      </div>

    </Layout>
  );
};

const SectionFirstScreen = ({ data }) => {
  const ImageHero = data.ImageHero.childImageSharp.gatsbyImageData;

  return (
    <section className="o-hidden py-5">
      <div className="container">
        <div className="row align-items-center min-vh-40">
          <div className="col-lg-7 text-left mb-4 mb-lg-0">
            <h1 className="display-4">
            Big Data for small Businesses
            </h1>
            <div className="my-4 lead">
              <div className="mb-1 badge badge-primary-2">Move your business forward:</div>
              <ul className="list-unstyled">
                <li className="d-flex py-1 align-items-center">
                  <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                    <IconCheck className="injected-svg icon bg-primary-3" />
                  </div>
                  <span className="lead">
                    <strong></strong> In the right product;
                  </span>
                </li>
                <li className="d-flex py-1 align-items-center">
                  <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                    <IconCheck className="injected-svg icon bg-primary-3" />
                  </div>
                  <span className="lead">
                    <strong>↑ 57%</strong> In the right market;
                  </span>
                </li>
                <li className="d-flex py-1 align-items-center">
                  <div className="icon-round icon-round-full icon-round-xs bg-primary-3 mr-2">
                    <IconCheck className="injected-svg icon bg-primary-3" />
                  </div>
                  <span className="lead">
                    <strong>Research</strong> At the right time
                  </span>
                </li>
              </ul>
            </div>
            <p className="lead">
              <strong>Understand your customer journey</strong>
            </p>
            <a
              id="consultation"
              className="btn btn-lg btn-primary typeform-share button text-light"
              href="http://localhost:8000/#"
              data-mode="popup"
              target="_blank">
               CTA TITLE <span className="small"> 2min</span>
            </a>
          </div>
          <div
            className="col-lg-5 text-center "
            style={{ position: "relative" }}
          >
            <div className="min-vw-50">
              <GatsbyImage alt={"ImageApp"} style={{maxWidth: 1000, height:450, marginRight:80}}
                           image={ImageHero} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionTrustedCompanies = () => (
  <section className="pt-0">
    <div className="container">
    <div className="row text-center mb-3">
        <div className="col">
        <Link
              to="/#"
              className="hover-arrow"
            >
              Media Data Tech supports all the available channels of the market (link to a page that reffer to this)
        </Link>
          <small className="">ALL IN ONE DASHBOARD - ACCESS YOUR DATA FROM EVERY PLATFORM YOU POST AND ADVERTISE</small>
        </div>
      </div>
  
      
      <div className="row">
        <div className="col">
          <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
            <li>
              <LogoVimeo className="" />
            </li>
            <li>
              <LogoYoutube className="" />
            </li>
            <li>
              <LogoAmazon className="" />
            </li>
            <li>
              <LogoItunes className="" />
            </li>
            <li>
              <LogoFb className="" />
            </li>
            <li>
              <LogoTwitter className="" />
            </li>
          </ul>
        </div>
      </div>        
    </div>
  </section>
);

const SectionTopUXAgency = ({ data }) => {
  const Logofeature1 = data.Logofeature1.childImageSharp.gatsbyImageData;
  const Logofeature2 = data.Logofeature2.childImageSharp.gatsbyImageData;
  const Logofeature3 = data.Logofeature3.childImageSharp.gatsbyImageData;
  const ImageDecor2 = data.ImageDecor2.childImageSharp.gatsbyImageData;

  return (
    <section className="top-ux-agency pt-3">
      <div className="container">
      <div className="decoration-wrapper d-none d-lg-block">
     <div
          className="decoration scale-1 top"
           style={{ left: "70%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
        >
          <GatsbyImage image={ImageDecor2} alt="ImageDecor2" className="" />
       </div>
      </div>
        <div className="row text-center">
          <div className="col-12 col-md-8 text-left">
            <h2 className="display-4">All together in ONE dashboard! You get:</h2>
            <p className="lead">
            Suspendisse in justo eu magna luctus suscipit. 
            Sed lectus. Integer euismod lacus luctus magna:
            </p>
          </div>
        </div>
        <div className="row mt-4 d-flex">
          <div className="col-12 col-md-4 col-lg-4">
            <a
              href="https://devpap.co.uk"
              target="_blank"
              className="card card-body"
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={Logofeature1}
                  alt=""
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  height={160}
                  style={{ height: "160px" }}
                  loading={"lazy"} />
              </div>

              
              <p className="mb-0" style={{ color: "#17313B" }}>
                <strong>
                  Easy to understand <span style={{ color: "#ff3d2e" }}> ★★★★★</span>
                </strong>
              </p>
              <h4> Granular and Total Ad Spend per Channel</h4>
              <p className="lead">
              Suspendisse in justo eu magna luctus suscipit. 
              Sed lectus. Integer euismod lacus luctus magna
              </p>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <a
              href="https://devpap.co.uk"
              target="_blank"
              className="card card-body"
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={Logofeature2}
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"} />
              </div>
              <p className="mb-0" style={{ color: "#4385F4" }}>
                <strong>
                  Data from everywhere  <span> 🌍 🌍 🌍</span>
                </strong>
              </p>
              <h4>KPIs Variable per Channel</h4>
              <p className="lead">
              KPIs Variable per Channel Manual Input or automated according to channel estimates
              </p>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <a
              href="https://devpap.co.uk"
              target="_blank"
              className="card card-body"
            >
              <div className="align-items-left mb-3">
                <GatsbyImage
                  image={Logofeature3}
                  alt=""
                  style={{ height: "160px" }}
                  imgStyle={{ objectFit: "contain", width: "auto" }}
                  loading={"lazy"} />
              </div>
              <p className="mb-0" style={{ color: "#ff934f" }}>
                <strong>
                  Performance <span style={{ color: "#ff934f" }}> ↑↑↑↑↑</span>
                  </strong>
              </p>
              <h4>Suspendisse in justo</h4>
              <p className="lead">
              Actual reach/ performance per channel and Total
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionDesignTeams = () => (

  <section className="bg-primary-alt has-divider section-capabilities">
    <div className="divider bg-white">
      <Divider1 className="bg-primary-alt" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 mt-6 mb-4">
          <h2 className="display-4">The devil is in details</h2>
          <p className="lead col-lg-8 pl-0">
          Suspendisse in justo eu magna luctus suscipit. <strong>Sed lectus </strong> Sed lectus. Integer euismod lacus luctus magna. 
          Quisque cursus, metus vitae pharetra auctor, sem massa mattis sem, 
          at interdum magna augue eget diam. Vestibulum ante ipsum primis 
          in faucibus orci luctus et ultrices posuere cubilia Curae;{" "} dwindling.
          </p>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div className="card card--no-hover card--common card--blog--vertical card-body justify-content-between">
            <div className="d-flex align-items-center mb-4">
              <div>
                <IconService1 className="icon icon-lg align-items-left" />
              </div>
              <h4 className="ml-2">Service 1</h4>
            </div>

            <p className="lead mb-4">
            Nulla facilisi. Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor. Integer id quam.
            </p>
            <h5>Integer lacinia sollicitudin massa:</h5>
            <ul className="lead mb-6">
              <li>Integer lacinia sollicitudin massa</li>
              <li>Integer lacinia sollicitudin massa</li>
              <li>Integer lacinia sollicitudin massa</li>
            </ul>

            <Link
              to="/#"
              id="freeconsultation"
              className="btn btn-lg btn-primary"
            >
              Learn more
            </Link>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6">
          <div className="card card--no-hover card--common card--blog--vertical card-body justify-content-between">
            <div className="d-flex align-items-center mb-4">
              <div>
                <IconService2 className="icon icon-lg align-items-left" />
              </div>
              <h4 className="ml-2">Service 2</h4>
            </div>

            <p className="lead mb-4">
            Nulla facilisi. Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor. Integer id quam.
            </p>
            <h5>Integer lacinia sollicitudin massa:</h5>
            <ul className="lead mb-6">
              <li>Integer lacinia sollicitudin massa</li>
              <li>Integer lacinia sollicitudin massa</li>
              <li>Integer lacinia sollicitudin massa</li>
            </ul>

            <Link
              to="/#"
              id="freeconsultation"
              className="btn btn-lg btn-primary"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionExpertise = ({ data }) => {
  const ImageGrow = data.ImageGrow.childImageSharp.gatsbyImageData;
  const ImageDecor = data.ImageDecor.childImageSharp.gatsbyImageData;

  return (
    <section className="bg-primary-alt pt-0 pt-md-5 pt-xl-7">
      <div className="decoration-wrapper d-none d-lg-block">
     <div
          className="decoration scale-1 top"
           style={{ left: "10%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
        >
          <GatsbyImage image={ImageDecor} alt="ImageDecor" className="" />
       </div>
      </div>
      
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-last mb-4 mb-md-0">
            <GatsbyImage image={ImageGrow} alt="ImageGrow" className="" />
          </div>

          <div className="col-md-6 d-flex align-items-center">
            <div>
              <h2 className="display-4">Suspendisse in</h2>
              <p className="lead mb-4">
              Suspendisse in justo eu magna luctus suscipit.
              </p>

              <h4 className="mb-3">Suspendisse in justo eu magna</h4>
              <h4 className="mb-3">Suspendisse in </h4>
              <h4 className="mb-3">Suspendisse in justo</h4>
              <h4 className="mb-3">Suspendisse in justo</h4>

              <div className="mt-4">
                <Link to="/about-us" className="lead mt-4 hover-arrow">
                  Learn More About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const IndustriesTabs = [
  {
    name: "TestTab",
    title: "Morbi in dui quis est pulvinar ullamcorper. ",
    caption:
      "Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada tellus",
    link: "https://mdtech.uk"
  },
  {
    name: "Visualise",
    title: "Curabitur sit amet mauris. Morbi in dui quis ",
    caption:
      "Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada tellus",
    link: "https://mdtech.uk"
  },
  {
    name: "Funnels ",
    title: "Curabitur sit amet mauris. Morbi in dui quis ",
    caption:
      "Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada tellus. Ut ultrices ultrices enim. Curabitur sit amet mauris. Morbi in dui quis est pulvinar ullamcorper. ",
    link: "https://mdtech.uk"
  },
  {
    name: "Funnels",
    title: "Curabitur sit amet mauris. Morbi in dui quiss",
    caption:
      "Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada tellus. Ut ultrices ultrices enim. Curabitur sit amet mauris. Morbi in dui quis est pulvinar ullamcorper. ",
    link: "https://mdtech.uk"
  }
];

class SectionIndustries extends Component {
  state = {
    activeTab: "TestTab"
  };

  setNewActiveTab = tabName => {
    this.setState({
      activeTab: tabName
    });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <section className="bg-primary-3 position-relative text-light">

        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center col-md-12 col-lg-10 col-xl-9">
              <span className="badge badge-primary-2 mb-2">Tabs for info</span>
              <div className="d-flex mb-5 justify-content-center">
                <ul className="nav custom-tabs custom-tabs--scroll lead">
                  {IndustriesTabs.map(({ name }, index) => (
                    <li
                      className="nav-item"
                      key={index}
                      onClick={() => this.setNewActiveTab(name)}
                    >
                      <span
                        className={`nav-link pl-0 ${
                          activeTab === name ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        {name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              {IndustriesTabs.map((info, index) => (
                <div
                  key={index}
                  className={`tab-animation ${
                    info.name === activeTab ? "active animated fadeIn" : ""
                  }`}
                >
                  <h3 className="h1">{info.title}</h3>
                  <p className="lead my-4">{info.caption}</p>
                  <a
                    href={info.link}
                    className="lead mt-2 mb-2 mb-md-0 hover-arrow text-primary-2"
                  >
                    Learn More
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const SectionOurProcess = ({ data }) => {
  const ImageDecor2 = data.ImageDecor2.childImageSharp.gatsbyImageData;

  return (
    <section className="has-divider o-hidden pt-4 pt-md-0">
      <div className="decoration-wrapper d-none d-lg-block">
        <div className="decoration scale-1" style={{ top: 160, right: "12%" }}>
        <GatsbyImage image={ImageDecor2} alt="ImageDecor2" className="" />
        </div>
      </div>

      <div className="container pt-0 pt-md-5 pt-xl-7">
        <div className="row justify-content-center text-center mb-2 mb-md-6">
          <div className="col-xl-8 col-lg-9 mt-5">
            <h2 className="display-4 mx-xl-5">The Process</h2>
            <p className="lead">As easy as you click</p>
          </div>
        </div>

        <div className="row justify-content-center text-center mb-2 mb-md-6">
          <div className="lead description m-0">
            <div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                <h5>Get in touch</h5>
                </div>
              </div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                <h5>Collect your data</h5>
                </div>
              </div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                <h5>Analyse</h5>

                </div>
              </div>
              <div className="process">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                <h5>Invest in the right place</h5>
                </div>
              </div>
            </div>
            <Link
              to="/#"
              className="lead mt-2 mb-2 mb-md-0 ml-4 ml-sm-6 hover-arrow"
            >
              About the Process
            </Link>
          </div>

          {/* <div className="col-md-6 d-flex align-items-center order-0 order-md-1 mb-4 mb-md-0">
            <GatsbyImage image={ImageOurProcess} alt="Our Process" style={{ flexGrow: 1 }} loading={"lazy"} />
          </div> */}
        </div>
      </div>
    </section>
  );
};
const SectionOurBook = ({ data }) => {
  const ImageBook = data.ImageBook.childImageSharp.gatsbyImageData;

  return (
    <section className="bg-primary text-light py-2 py-md-1 o-hidden">
      <div className="decoration-wrapper d-none d-lg-block">
        <div
          className="decoration scale-1"
          style={{ top: -30, left: "2%", transform: "rotate(-74.43deg)" }}
        >
          {/* <DecorationBlob6 className="bg-yellow" /> */}
        </div>
      </div>

      <div className="container align-items-center text-center text-md-left">
        <div className="row py-6 align-items-center">
          <div className="col-12 col-md-6 text-center pb-4 pb-md-0">
            <GatsbyImage
              image={ImageBook}
              alt="test"
              className="" loading={"lazy"}/>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <div>
              <span className="badge badge-primary-2 mb-2 lead">
              Morbi in dui quis est pulvinar ullamcorper. 
              </span>
              <h2 className="h1">Morbi in dui quis est</h2>
              <p className="lead">
              Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. 
              Vestibulum tincidunt malesuada tellus
              </p>
              <a
                href="https://#"
                className="btn btn-lg btn-white mt-3"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const SectionBlog = ({ FeaturedArticlesData }) => (
  <section className="bg-primary-alt pb-0 pb-md-5 pb-xl-7">
    <div className="decoration-wrapper d-none d-lg-block">
      <div
        className="decoration scale-1"
        style={{ top: -120, right: "15%", transform: "rotate(55.54deg)" }}
      >
        <DecorationLine1 className="bg-yellow" />
      </div>
    </div>

    <div className="container pb-6">
      <div className="row">
        <div className="col-12 row align-items-center mb-4">
          <div className="col-md-8">
            <h2 className="display-4">UX Blogs and News</h2>
          </div>
          <div className="col-md-4 text-md-right">
            <Link to="/blog" className="lead hover-arrow">
              Discover All UX Blogs
            </Link>
          </div>
        </div>

        {FeaturedArticlesData.map(({ node }) => (
          <div className="col-md-6 col-lg-4" key={node.id}>
            <CardBlog cardData={node} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default IndexPage;

export const query = graphql`query IndexQuery {

  ImageDecor: file(relativePath: {eq: "photos/decor2.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 180)
    }
  }
  ImageDecor2: file(relativePath: {eq: "photos/xwni.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 162)
    }
  }

  ImageGrow: file(relativePath: {eq: "photos/chart-mdtech.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageBook: file(
    relativePath: {eq: "photos/mdtech.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  ImageHero: file(relativePath: {eq: "photos/earth-mdtech.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 1800)
    }
  }
  Logofeature1: file(relativePath: {eq: "photos/ux-design-agency-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  Logofeature2: file(
    relativePath: {eq: "photos/ux-design-agency-3.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  Logofeature3: file(relativePath: {eq: "photos/ux-design-agency-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`;
